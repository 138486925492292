<template lang="pug">
	section#organograma
		.wrapper
			//- h3 Visão geral
			//- h4 Clique nos botões abaixo e conheça o sistema de planejamento urbano de Curitiba.
			OrganogramaBox(v-for="item, index in contents", :contents="item", :key="item.content.title", :backTo="backTo", :class="{ first: index == 0 }")
</template>

<script>
import { components, props } from '@/mixins/components'
import qs from 'qs'

export default {
  name: 'section-organograma',
  props,
  components,
  computed: {
    backTo () {
      return qs.stringify({
        to: { name: 'Pagina', params: this.$route.params },
        page: 'à Visão Geral do Plano Diretor'
      })
    }
  }
}
</script>

<style lang="stylus" scoped src="./Organograma.styl"></style>
